import moment from "moment";

// 订单列表
import req_list_data from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_queryHotelCancelOrderList";
// 取消退订订单
import req_cancel_refund from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_cancelApplyOrder";

import EmptyReminder from '../components/empty-reminder/index.vue';


export default {
  components: {
    EmptyReminder
  },
  data() {
    return {
      loading: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        orderNo: "",
        evectionUserName: "",
        cancelStatus: "-1",
        evectionType: "",
      },
      list: [],
      allocation: {
        cancelStatus: [
          { label: "全部", value: "-1" },
          // { label: "超标审批中", value: "18" },
          { label: "退订中", value: "0" },
          { label: "退订成功", value: "1" },
          // { label: "待平台确认", value: "2" },
          { label: "退订失败", value: "3" },
          { label: "已取消", value: "6" },
        ],
        evectionType: [
          { label: "全部", value: "" },
          { label: "个人预定", value: "1" },
          { label: "出差单预定", value: "2" },
        ],
        pageSize: [10, 15, 20, 25, 30],
        total: 0,

        cancelForm: {
          orderNo: "",
          cancelRemark: "",
        },
      },
      pager: {
        total: 0,
        pageSizes: [10, 15, 20, 25, 30],
      }
    };
  },
  methods: {
    req_list() {
      this.loading = true;
      this.list = [];
      req_list_data(this.form).then((res) => {
        this.list = res.pageResult.pageData;
        this.pager.total = res.pageResult.totalCount;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      });
    },
    init () {
      const currentPage = Number(this.$route.query.currentPage);
      if (currentPage) {
        this.form.currentPage = currentPage;
        this.pager.total = currentPage * 10;
        // this.$store.commit({
        //   type: "update_hotel_unsubscribe_currentPage",
        //   currentPage,
        // });
      }
      this.req_list();
    },
    status_change (val) {
      if (!val) this.form.cancelStatus = '-1';
    },
    search() {
      if (this.loading) return;
      this.form.currentPage = 1;
      this.req_list();
    },
    to_refund_detail(orderNo) {
      this.$router.push({
        name: "admin-hotel-refund-detail",
        query: {
          orderNo,
          // listType: '2',
        }
      });
    },
    cancel_refund (orderNo, applyNo) {
      this.$confirm("是否确认取消退订?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          req_cancel_refund({ orderNo, applyNo, cancelRemark: "" })
            .then((res) => {
              this.$message.success("取消退订成功");
              this.req_list();
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    handleSizeChange (pageSize) {
      this.form.pageSize = pageSize;
      this.handleCurrentChange()
    },
    handleCurrentChange (currentPage) {
      this.form.currentPage = currentPage;
      this.req_list();
    },
    // 订单状态
    getStatusColor(val) {
      if (val === 0 || val === 2 || val === 18) {
        return 'orange'
      } else if (val === 11) {
        return 'green'
      } else {
        return 'red'
      }
    },
  },
  mounted() {
    this.init();
  },
  filters: {
    format_time(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    format_week(val) {
      switch (moment(val).day()) {
        case 1:
          return "周一";
          break;
        case 2:
          return "周二";
          break;
        case 3:
          return "周三";
          break;
        case 4:
          return "周四";
          break;
        case 5:
          return "周五";
          break;
        case 6:
          return "周六";
          break;
        case 0:
          return "周日";
          break;
      }
    },
    format_person(val) {
      if (val) {
        return JSON.parse(val)
        .map((item) => item.lastName)
        .join("/");
      }
    },
    format_status(val) {
      switch (Number(val)) {
        case 0:
          return "退订中";
          break;
        case 1:
          return "退订成功";
          break;
        case 2:
          return "待平台确认";
          break;
        case 3:
          return "退订失败";
          break;
        case 4:
          return "未退订，待审批";
          break;
      }
    },
  },
};
