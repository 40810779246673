// 取消申请退订审核-酒店  http://219.128.52.2:18765/doc.html#/haolv-consumer/t-od-hotel-order-interim-controller/cancelApplyOrderUsingPOST
const __request = require(`./__request/__request_contentType_json`)
const consumer_t_od_hotel_order_interim_cancelApplyOrder = (data) => {
  if (!data) data = {};
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/t-od-hotel-order-interim/cancelApplyOrder',
    data: data
  }
  return __request(pParameter)
}
export default consumer_t_od_hotel_order_interim_cancelApplyOrder