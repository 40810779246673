// 获取酒店取消订单列表 http://219.128.52.2:18765/doc.html#/haolv-consumer/t-od-hotel-order-interim-controller/queryHotelCancelOrderListUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_t_od_hotel_order_interim_queryHotelCancelOrderList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-order-interim/queryHotelCancelOrderList',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_hotel_order_interim_queryHotelCancelOrderList;